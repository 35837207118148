import {Component, Inject, OnInit} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {InvoiceConfig} from '../../model/invoiceConfig';
import {environment} from '../../../../environments/environment';
import {InvoiceService} from '../../services/invoice.service';

@Component({
  selector: 'app-invoice-dialog',
  templateUrl: './invoice-dialog.component.html',
  styleUrls: ['./invoice-dialog.component.scss']
})
export class InvoiceDialogComponent implements OnInit {
  pdfLink: SafeResourceUrl;
  loading = true;

  constructor(
    private service: InvoiceService,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) private data: {language: string, invoice: InvoiceConfig}
  ) { }

  ngOnInit() {
    this.loading = true;
    this.service.invoicePreview(this.data.language, this.data.invoice).subscribe(data => {
      this.service.get(`${data.file}.pdf`).subscribe(result => {
        this.loading = false;
        if (result.success) {
          const linkSource = 'data:application/pdf;base64,' + result.data;
          this.pdfLink = this.sanitizer.bypassSecurityTrustResourceUrl(linkSource);
        }
      });
    });
  }

}
