import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {LayoutFormUpdateService} from '../../services/layout-form-update.service';
import {CertificateConfig} from '../../model/certificateConfig';
import {MatDialog} from '@angular/material/dialog';
import {CertificateDialogComponent} from '../../dialog/certificate-dialog/certificate-dialog.component';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {CertificateService} from '../../services/certificate.service';
import {FileService} from '../../../services/file.service';
import {Label} from '../../model/label';
import {SelectItem} from 'primeng/api';

@Component({
  selector: 'app-certificate-config',
  templateUrl: './certificate-config.component.html',
  styleUrls: ['./certificate-config.component.scss']
})
export class CertificateConfigComponent implements OnInit, OnDestroy {
  logoMainUrl: string;
  logoHeaderUrl: string;
  logoSigneeUrl: string;
  origLogoMainUrl: string;
  origLogoHeaderUrl: string;
  origlogoSigneeUrl: string;

  logoMainDisplayUrl: SafeResourceUrl;
  logoHeaderDisplayUrl: SafeResourceUrl;
  logoSigneeDisplayUrl: SafeResourceUrl;

  certificateForm: FormGroup;
  updateSubscription: Subscription;

  labelDropdowns: SelectItem[];

  @Input() language: 'en' | 'de' = 'en';
  @Output() certificateResult = new EventEmitter<CertificateConfig>();
  @Output() logoMainResult = new EventEmitter<File>();
  @Output() logoHeaderResult = new EventEmitter<File>();
  @Output() signeeLogoResult = new EventEmitter<File>();

  constructor(
    private fb: FormBuilder,
    private service: LayoutFormUpdateService,
    private certificateService: CertificateService,
    private fileService: FileService,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer
  ) {
  }

  ngOnInit() {
    this.updateSubscription = this.service.certificate$.subscribe(certificate => {
      if (certificate.id === -1) {
        certificate.id = null;
        this.certificateDefaults();
      }

      this.certificateService.getAllLabels().subscribe(labels => {
        this.labelDropdowns = labels.map((label: Label) => {
          return {label: this.language === 'de' ? label.textDe : label.textEn, value: label.id};
        });
      });

      this.certificateForm.patchValue(certificate);

      if (certificate.logoMainPath && certificate.logoMainPath !== '') {
        this.origLogoMainUrl = certificate.logoMainPath;

        this.fileService.get(certificate.logoMainPath).subscribe(data => {
          if (data.success) {
            this.logoMainUrl = data.data;
            this.certificateForm.patchValue({logoMain: data.data});
            this.logoMainDisplayUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
              this.logoMainUrl.replace('data:image/svg;base64,', 'data:image/svg+xml;base64,')
            );
          }
        });
      }

      if (certificate.logoHeaderPath && certificate.logoHeaderPath !== '') {
        this.origLogoHeaderUrl = certificate.logoHeaderPath;

        this.fileService.get(certificate.logoHeaderPath).subscribe(data => {
          if (data.success) {
            this.logoHeaderUrl = data.data;
            this.certificateForm.patchValue({logoHeader: data.data});
            this.logoHeaderDisplayUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
              this.logoHeaderUrl.replace('data:image/svg;base64,', 'data:image/svg+xml;base64,')
            );
          }
        });
      }

      if (certificate.signeeLogoPath && certificate.signeeLogoPath !== '') {
        this.origlogoSigneeUrl = certificate.signeeLogoPath;

        this.fileService.get(certificate.signeeLogoPath).subscribe(data => {
          if (data.success) {
            this.logoSigneeUrl = data.data;
            this.certificateForm.patchValue({signeeLogo: data.data});
            this.logoSigneeDisplayUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
              this.logoSigneeUrl.replace('data:image/svg;base64,', 'data:image/svg+xml;base64,')
            );
          }
        });
      }
    });

    this.certificateForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(150)]],
      logoMain: [null],
      logoHeader: [null],
      introText: ['', [Validators.required, Validators.maxLength(350)]],
      subText: ['', [Validators.required, Validators.maxLength(280)]],
      projectHeadline: ['', [Validators.required, Validators.maxLength(120)]],
      location: ['', [Validators.required, Validators.maxLength(120)]],
      signeeText: ['', [Validators.required, Validators.maxLength(120)]],
      signeeLogo: [null],
      showPublicId: false,
      labelId: ['', [Validators.required]],
    });

    this.certificateForm.valueChanges.subscribe(data => {
      if (this.certificateForm.valid) {
        this.certificateResult.emit({
          ...this.certificateForm.value,
          logoMainPath: this.origLogoMainUrl,
          logoHeaderPath: this.origLogoHeaderUrl,
          signeeLogoPath: this.origlogoSigneeUrl,
        });
      } else {
        this.certificateResult.emit(null);
      }
    });
  }

  ngOnDestroy() {
    this.updateSubscription.unsubscribe();
  }

  certificatePreview() {
    const dialogRef = this.dialog.open(CertificateDialogComponent, {
      width: '50vw',
      height: '80vh',
      data: {
        language: this.language,
        certificate: {
          name: this.certificateForm.get('name')?.value,
          introText: this.certificateForm.get('introText')?.value,
          subText: this.certificateForm.get('subText')?.value,
          projectHeadline: this.certificateForm.get('projectHeadline')?.value,
          location: this.certificateForm.get('location')?.value,
          signeeText: this.certificateForm.get('signeeText')?.value,
          showPublicId: this.certificateForm.get('showPublicId')?.value,
          logoMain: this.certificateForm.get('logoMain')?.value,
          logoHeader: this.certificateForm.get('logoHeader')?.value,
          logoSignee: this.certificateForm.get('logoSignee')?.value,
          labelId: this.certificateForm.get('labelId').value
        }
      }
    });
  }

  certificateDefaults() {
    this.certificateService.defaults(this.language).subscribe(defaultConfig => {
      this.origLogoHeaderUrl = defaultConfig.data.logoHeaderPath;
      this.logoHeaderUrl = defaultConfig.data.logoHeaderPath;
      this.logoHeaderDisplayUrl = this.sanitizer.bypassSecurityTrustResourceUrl(defaultConfig.header);

      this.origlogoSigneeUrl = defaultConfig.data.signeeLogoPath;
      this.logoSigneeUrl = defaultConfig.data.signeeLogoPath;
      this.logoSigneeDisplayUrl = this.sanitizer.bypassSecurityTrustResourceUrl(defaultConfig.signee);

      this.certificateForm.patchValue({
        name: defaultConfig.data.name,
        introText: defaultConfig.data.introText,
        subText: defaultConfig.data.subText,
        projectHeadline: defaultConfig.data.projectHeadline,
        location: defaultConfig.data.location,
        signeeText: defaultConfig.data.signeeText,
        labelId: defaultConfig.data.labelId,
      });

      this.certificateResult.emit({
        ...this.certificateForm.value,
        logoHeaderPath: this.origLogoHeaderUrl,
        signeeLogoPath: this.origlogoSigneeUrl,
      });

      // this.origLogoHeaderUrl = environment.certificateHeaderUrl.replace('{language}', this.language);
      // this.logoHeaderUrl = `${environment.basicUrl}${this.origLogoHeaderUrl}`;
      //
      // this.origlogoSigneeUrl = environment.certificateSigneeUrl;
      // this.logoSigneeUrl = `${environment.basicUrl}${this.origlogoSigneeUrl}`;
    });
  }

  onDragOver(event) {
    event.preventDefault();
  }

  dropLogoMain(event) {
    event.preventDefault();
    if (event && event.dataTransfer && event.dataTransfer.files) {
      this.saveLogoMain(event.dataTransfer.files[0]);
    }
  }

  dropLogoHeader(event) {
    event.preventDefault();
    if (event && event.dataTransfer && event.dataTransfer.files) {
      this.saveLogoHeader(event.dataTransfer.files[0]);
    }
  }

  dropLogoSignee(event) {
    event.preventDefault();
    if (event && event.dataTransfer && event.dataTransfer.files) {
      this.saveLogoSignee(event.dataTransfer.files[0]);
    }
  }

  onLogoMainSelected(event) {
    this.saveLogoMain((event.target as HTMLInputElement).files[0]);
  }

  onLogoHeaderSelected(event) {
    this.saveLogoHeader((event.target as HTMLInputElement).files[0]);
  }

  onLogoSigneeSelected(event) {
    this.saveLogoSignee((event.target as HTMLInputElement).files[0]);
  }

  private saveLogoMain(file) {
    this.certificateForm.patchValue({
      logoMain: file
    });
    this.certificateForm.get('logoMain').updateValueAndValidity();
    this.logoMainResult.emit(file);

    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      this.logoMainUrl = reader.result as string;
      this.logoMainDisplayUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.logoMainUrl);
    };
    reader.readAsDataURL(file);
  }

  private saveLogoHeader(file) {
    this.certificateForm.patchValue({
      logoHeader: file
    });
    this.certificateForm.get('logoHeader').updateValueAndValidity();
    this.logoHeaderResult.emit(file);

    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      this.logoHeaderUrl = reader.result as string;
      this.logoHeaderDisplayUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.logoHeaderUrl);
    };
    reader.readAsDataURL(file);
  }

  private saveLogoSignee(file) {
    this.certificateForm.patchValue({
      signeeLogo: file
    });
    this.certificateForm.get('signeeLogo').updateValueAndValidity();
    this.signeeLogoResult.emit(file);

    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      this.logoSigneeUrl = reader.result as string;
      this.logoSigneeDisplayUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.logoSigneeUrl);
    };
    reader.readAsDataURL(file);
  }

  removeLogoMain(event) {
    event.preventDefault();
    event.stopPropagation();
    this.certificateForm.patchValue({
      logoMain: null
    });
    this.certificateForm.get('logoMain').updateValueAndValidity();
    this.logoMainResult.emit(null);

    this.logoMainUrl = null;
    this.logoMainDisplayUrl = null;
  }

  removeLogoHeader(event) {
    event.preventDefault();
    event.stopPropagation();
    this.certificateForm.patchValue({
      logoHeader: null
    });
    this.certificateForm.get('logoHeader').updateValueAndValidity();
    this.logoHeaderResult.emit(null);

    this.logoHeaderUrl = null;
    this.logoHeaderDisplayUrl = null;
  }

  removeLogoSignee(event) {
    event.preventDefault();
    event.stopPropagation();
    this.certificateForm.patchValue({
      signeeLogo: null
    });
    this.certificateForm.get('signeeLogo').updateValueAndValidity();
    this.signeeLogoResult.emit(null);

    this.logoSigneeUrl = null;
    this.logoSigneeDisplayUrl = null;
  }

}
