import {TableObject} from '../../layout/model/table-object';
import {TableElement} from '../../layout/model/table-element';

export class MandatorListConfig implements TableObject {
  private static instance: MandatorListConfig;
  columns: TableElement[] = [];

  constructor(private locale: 'de' | 'en') {
    // ToDo language
    if (!MandatorListConfig.instance) {
      this.columns = this.getColumnDefinition();
      MandatorListConfig.instance = this;
    }
    return MandatorListConfig.instance;
  }

  getDisplayedListColumns(): string[] {
    return ['firstname', 'lastname', 'company', 'email'];
  }

  getColumnDefinition(): TableElement[] {
    const columnDefs: TableElement[] = [];
    columnDefs.push({key: 'firstname', title: 'Firstname', value: 'personFirstname'});
    columnDefs.push({key: 'lastname', title: 'Lastname', value: 'personLastname'});
    columnDefs.push({key: 'company', title: 'Company', value: 'companyname'});
    columnDefs.push({key: 'email', title: 'E-Mail', value: 'email'});
    return columnDefs;
  }

}
