import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MandateListComponent } from './mandate-list/mandate-list.component';
import { MandateDetailComponent } from './mandate-detail/mandate-detail.component';
import {MandateRoutingModule} from './mandate-routing.module';
import {LayoutModule} from '../layout/layout.module';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {DialogModule} from 'primeng/dialog';
import {ButtonModule} from 'primeng/button';



@NgModule({
  declarations: [MandateListComponent, MandateDetailComponent],
  imports: [
    CommonModule,
    MandateRoutingModule,
    LayoutModule,
    MatIconModule,
    MatButtonModule,
    DialogModule,
    ButtonModule,
    ButtonModule
  ]
})
export class MandateModule { }
