export const environment = {
  production: true,

  basicUrl: 'https://dev-myfirstclimate.com/',
  fileUrl: 'https://dev-myfirstclimate.com/file-api/',
  orderUrl: 'https://dev-myfirstclimate.com/order-api/',
  orderPdfUrl: 'https://dev-myfirstclimate.com/pdf-api/',
  apiPath: 'api/',
  orderCertificatePath: 'order/certificate/',
  orderInvoicePath: 'order/invoice/',
  authenticationPath: 'api/login_check',
  embedOpenCalcLink: 'https://client.dev-myfirstclimate.com/de/cc/fc'
};
