<div class="strength">
  <h3 i18n="@@PasswordStrengthTitle">Password strength</h3>
  <div class="strength-wrapper">
    <ul class="strength-bar">
      <li class="point" [style.background-color]="bg0"></li>
      <li class="point" [style.background-color]="bg1"></li>
      <li class="point" [style.background-color]="bg2"></li>
      <li class="point" [style.background-color]="bg3"></li>
    </ul>
    <i class="icon pi pi-info-circle"
       pTooltip="The password must be at least 8 characters long, contain a number, an uppercase letter, a lowercase letter and a special character."
       i18n-pTooltip="@@PasswordStrengthTitleInfo" tooltipPosition="bottom"></i>
  </div>

  <br>
</div>
