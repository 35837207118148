import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Customer} from '../../customer/model/customer';
import {User} from '../../layout/model/user';
import {Person} from '../../layout/model/person';
import {Company} from '../../layout/model/company';
import {Address} from '../../layout/model/address';
import {Contact} from '../../layout/model/contact';
import {CertificateConfig} from '../../layout/model/certificateConfig';
import {InvoiceConfig} from '../../layout/model/invoiceConfig';
import {CustomerService} from '../../customer/services/customer.service';
import {ActivatedRoute, Router} from '@angular/router';
import {LayoutFormUpdateService} from '../../layout/services/layout-form-update.service';
import {FileService} from '../../services/file.service';
import {Mandator} from '../model/mandator';
import {MandateService} from '../services/mandate.service';
import {UtilFactory} from '../../layout/model/util-factory';
import {Setting} from '../../layout/model/setting';
import {ActionService} from '../../services/action.service';

@Component({
  selector: 'app-mandate-detail',
  templateUrl: './mandate-detail.component.html',
  styleUrls: ['./mandate-detail.component.scss']
})
export class MandateDetailComponent implements OnInit, AfterViewInit {
  mandator: Mandator;
  user: User;
  person: Person;
  company: Company;
  address: Address;
  contact: Contact;
  setting: Setting;
  certificate: CertificateConfig;
  invoice: InvoiceConfig;

  chosenLanguage = 'de';

  mode: 'new' | 'edit';
  displayNewMandatorModal = false;
  newMandatorId: number;

  logoMain;
  logoHeader;
  signeeLogo;

  constructor(
    private mandatorService: MandateService,
    private route: ActivatedRoute,
    private actionService: ActionService,
    private router: Router,
    private layoutService: LayoutFormUpdateService,
    private fileService: FileService
  ) {
  }

  ngOnInit() {
    this.mode = 'new';
    this.user = UtilFactory.defaultUser;
    this.person = UtilFactory.defaultPerson;
    this.company = null;
    this.address = UtilFactory.defaultAddress;
    this.contact = UtilFactory.defaultContact;
    this.setting = UtilFactory.defaultSetting;
    this.certificate = UtilFactory.defaultCertificateConfig;
    this.invoice = UtilFactory.defaultInvoiceConfig;

    const routeId = this.route.snapshot.paramMap.get('id');
    if (routeId && !isNaN(+routeId)) {
      this.mode = 'edit';
      this.mandatorService.getSingle(routeId).subscribe(mandator => {
        this.mandator = mandator;
        this.layoutService.updateUser(this.mandator.user);
        this.layoutService.updatePerson(this.mandator.person);
        this.layoutService.updateCompany(this.mandator.company);
        this.layoutService.updateAddress(this.mandator.address);
        this.layoutService.updateContact(this.mandator.contact);
        this.layoutService.updateSetting(this.mandator.setting);
        this.layoutService.updateCertificate(this.mandator.certificateConfig);
        this.layoutService.updateInvoice(this.mandator.invoiceConfig);
      });
    }
  }

  ngAfterViewInit() {
    const routeId = this.route.snapshot.paramMap.get('id');
    if (routeId === null) {
      setTimeout(() => {
        this.layoutService.updateCertificate(this.certificate);
        this.layoutService.updateInvoice(this.invoice);
      }, 0);
    }
  }

  changeChoosenLanguage(language: string) {
    this.chosenLanguage = language;
    this.user.language = this.chosenLanguage;
  }

  areasAreValid() {
    return this.user
      && this.person
      && this.company
      && this.address
      && this.contact
      && this.setting
      && this.certificate
      && this.invoice;
  }

  backToList() {
    this.router.navigate(['/mandators']);
  }

  saveMandator() {
    this.actionService.showSaveDialog();
    this.checkSaveMainLogo();
  }

  checkSaveMainLogo() {
    if (this.logoMain) {
      this.fileService.uploadMainLogo(this.logoMain).subscribe(data => {
        this.certificate.logoMainPath = data.contentUrl;
        this.checkSaveHeaderLogo();
      });
    } else {
      this.checkSaveHeaderLogo();
    }
  }

  checkSaveHeaderLogo() {
    if (this.logoHeader) {
      this.fileService.uploadHeaderLogo(this.logoHeader).subscribe(data => {
        this.certificate.logoHeaderPath = data.contentUrl;
        this.checkSaveSigneeLogo();
      });
    } else {
      this.checkSaveSigneeLogo();
    }
  }

  checkSaveSigneeLogo() {
    if (this.signeeLogo) {
      this.fileService.uploadSigneeLogo(this.signeeLogo).subscribe(data => {
        this.certificate.signeeLogoPath = data.contentUrl;
        this.saveData();
      });
    } else {
      this.saveData();
    }
  }

  saveData() {
    const mandatorToSave = this.mandator || new Mandator();
    mandatorToSave.user = this.user;
    mandatorToSave.person = this.person;
    mandatorToSave.company = this.company;
    mandatorToSave.address = this.address;
    mandatorToSave.contact = this.contact;
    mandatorToSave.setting = this.setting;
    mandatorToSave.certificateConfig = this.certificate;
    mandatorToSave.invoiceConfig = this.invoice;

    this.mandatorService.save(mandatorToSave).subscribe((mandator: Mandator) => {
        this.actionService.hideSaveDialog();
        if (this.mode === 'edit') {
          this.backToList();
        } else {
          this.newMandatorId = mandator.id;
          this.displayNewMandatorModal = true;
        }
      },
      err => this.actionService.showError()
    );
  }

  reloadMandator() {
    this.router.navigate(['/mandators/edit/' + this.newMandatorId]);
  }
}
