<form [formGroup]="companyForm">
  <p-fieldset legend="Company" i18n-legend="@@CompanyFieldsetLabel" class="form-fieldset" [class.valid]="companyForm.valid" [class.invalid]="required && companyForm.invalid">

    <mat-form-field>
      <input matInput type="text" placeholder="name" i18n-placeholder="@@CompanyNamePlaceholder" formControlName="name" maxlength="100">
    </mat-form-field>
    <mat-form-field>
      <input matInput type="text" placeholder="contact person" i18n-placeholder="@@CompanyContactPlaceholder" formControlName="contact" maxlength="100">
    </mat-form-field>
    <mat-form-field>
      <input matInput type="text" placeholder="vat" i18n-placeholder="@@CompanyVatPlaceholder" formControlName="vat" maxlength="12">
    </mat-form-field>
  </p-fieldset>
</form>
