<form [formGroup]="settingForm">
  <p-fieldset legend="Setting" i18n-legend="@@SettingFieldsetLabel" class="form-fieldset" [class.valid]="settingForm.valid" [class.invalid]="settingForm.invalid">

    <span class="ui-float-label mb-4">
      <input id="inpTermOfPayment" pInputText type="number" formControlName="termOfPayment">
      <label for="inpTermOfPayment" i18n="@@SettingTermOfPaymentPlaceholder">Term of Payment (days)</label>
    </span>

    <p-checkbox formControlName="instantInvoice" label="Send Invoice via E-Mail" i18n-label="@@SettingAutomatedInvoiceGenerationLabel" binary="true" class="mb-4"></p-checkbox>

    <span class="ui-float-label mb-4">
      <input id="inpVatRate" pInputText type="number" formControlName="vatRate" min="0" max="100">
      <label for="inpVatRate" i18n="@@SettingVatRatePlaceholder">VAT Rate (%)</label>
    </span>

    <span class="ui-float-label mb-4">
      <input id="inpFee" pInputText type="number" formControlName="fee" min="0" step="0.01">
      <label for="inpFee" i18n="@@SettingFeePlaceholder">Fee (for Company Calculator only)</label>
    </span>
  </p-fieldset>
</form>
