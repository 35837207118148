<div class="app-title">
  <h1 i18n="@@MandatorsListTitle">Mandators</h1>
  <a routerLink="add" class="add-button-wrapper">
    <button mat-raised-button class="btn-fc-primary">
      <mat-icon>add</mat-icon>
      <ng-container i18n="@@MandatorsListNewButtonText">New Mandator</ng-container>
    </button>
  </a>
</div>

<app-table *ngIf="dataSource"
           [dataSource]="dataSource"
           [config]="config"
           [editable]="true"
></app-table>
