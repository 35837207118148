import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {tap} from 'rxjs/operators';
import {DataService} from './data.service';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private forgotPasswordPath = 'forgot_password';
  private tokenName = 'fc_auth';
  private refreshName = 'fc_refresh';
  private expirationTokenSource = new Subject();
  private rolesSetted = false;
  expirationToken$ = this.expirationTokenSource.asObservable();

  constructor(private http: HttpClient, private dataService: DataService) { }

  get token() {
    return this.isLoggedIn() ? localStorage.getItem(this.tokenName) : null;
  }

  login(username: string, password: string) {
    return this.http
      .post(`${environment.basicUrl}${environment.authenticationPath}`, {username, password})
      .pipe(
        tap(auth => this.setStorage(auth))
      );
  }

  tokenIsExpired() {
    this.expirationTokenSource.next();
  }


  forgotPassword(username: string): Observable<any> {
    return this.http.post(`${environment.basicUrl}forgot_password`, {
      username,
      language: this.dataService.getLocale()
    });
  }

  resetPassword(input: {password: string, language: string, token: string}): Observable<any> {
    return this.http.post(`${environment.basicUrl}reset-password`, input);
  }

  logout(): void {
    localStorage.removeItem(this.tokenName);
  }

  isLoggedIn(): boolean {
    const authToken = localStorage.getItem(this.tokenName);
    if (authToken && authToken.length > 1) {
      if (!this.rolesSetted) {
        this.rolesSetted = true;
        this.setUsername();
        this.setUserRoles();
        this.setUuid();
      }
    }
    return authToken && authToken.length > 1;
  }

  private setStorage(auth: any): void {
    if (auth) {
      //console.log(`Bearer ${auth.token}`);
      localStorage.setItem(this.tokenName, auth.token);
      localStorage.setItem(this.refreshName, auth.refresh_token);
      this.setUsername();
      this.setUserRoles();
      this.setUuid();
    }
  }

  private setUuid() {
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(localStorage.getItem(this.tokenName));
    this.dataService.setUserId(decodedToken.uuid);
  }

  private setUsername() {
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(localStorage.getItem(this.tokenName));
    this.dataService.setUsername(decodedToken.name);
  }

  private setUserRoles() {
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(localStorage.getItem(this.tokenName));
    this.dataService.setRoles(decodedToken.roles);
  }

}
