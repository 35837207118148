import {User} from '../../layout/model/user';
import {Person} from '../../layout/model/person';
import {Company} from '../../layout/model/company';
import {Address} from '../../layout/model/address';
import {Contact} from '../../layout/model/contact';
import {CertificateConfig} from '../../layout/model/certificateConfig';
import {InvoiceConfig} from '../../layout/model/invoiceConfig';
import {Setting} from '../../layout/model/setting';
import {FeUnit} from '../../layout/model/feUnit';

export class Mandator {
  id: number | null;
  user: User;
  person: Person;
  company: Company;
  address: Address;
  contact: Contact;
  setting: Setting;
  certificateConfig: CertificateConfig;
  invoiceConfig: InvoiceConfig;
  units: FeUnit[];

  get companyname(): string {
    return this.company.name || '-';
  }

  get personFirstname(): string {
    return this.person.firstname || '-';
  }

  get personLastname(): string {
    return this.person.lastname || '-';
  }

  get email(): string {
    return this.person.email || '-';
  }

  get dropdownLabel(): string {
    return `${this.personFirstname} ${this.personLastname}`;
  }
}
