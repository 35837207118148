import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoggedInGuard} from './guards/logged-in.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'preview',
    loadChildren: () => import('./preview/preview.module').then(m => m.PreviewModule),
    canActivate: [LoggedInGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [LoggedInGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
    data: { showHeader: false, showSidebar: false }
  },
  {
    path: 'beusers',
    loadChildren: () => import('./user/user.module').then(m => m.UserModule),
    canActivate: [LoggedInGuard]
  },
  {
    path: 'mandators',
    loadChildren: () => import('./mandate/mandate.module').then(m => m.MandateModule),
    canActivate: [LoggedInGuard]
  },
  {
    path: 'sdg',
    loadChildren: () => import('./sdg/sdg.module').then(m => m.SdgModule),
    canActivate: [LoggedInGuard]
  },
  {
    path: 'project-type',
    loadChildren: () => import('./project-type/project-type.module').then(m => m.ProjectTypeModule),
    canActivate: [LoggedInGuard]
  },
  {
    path: 'project-standard',
    loadChildren: () => import('./project-standard/project-standard.module').then(m => m.ProjectStandardModule),
    canActivate: [LoggedInGuard]
  },
  {
    path: 'projects',
    loadChildren: () => import('./project/project.module').then(m => m.ProjectModule),
    canActivate: [LoggedInGuard]
  },
  {
    path: 'customer',
    loadChildren: () => import('./customer/customer.module').then(m => m.CustomerModule),
    canActivate: [LoggedInGuard]
  },
  {
    path: 'emission-factor',
    loadChildren: () => import('./emission-factor/emission-factor.module').then(m => m.EmissionFactorModule),
    canActivate: [LoggedInGuard]
  },
  {
    path: 'order',
    loadChildren: () => import('./order/order.module').then(o => o.OrderModule),
    canActivate: [LoggedInGuard]
  },
  {
    path: 'ecomlog',
    loadChildren: () => import('./log-entry/log-entry.module').then(m => m.LogEntryModule),
    canActivate: [LoggedInGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
