import { Component, OnInit } from '@angular/core';
import {MandateService} from '../services/mandate.service';
import {DataService} from '../../services/data.service';
import {MandatorListConfig} from '../model/mandator-list-config';

@Component({
  selector: 'app-mandate-list',
  templateUrl: './mandate-list.component.html',
  styleUrls: ['./mandate-list.component.scss']
})
export class MandateListComponent implements OnInit {
  config: MandatorListConfig;
  dataSource = null;

  constructor(private mandateService: MandateService, private dataService: DataService) {
    this.config = new MandatorListConfig(dataService.getLocale());
  }

  ngOnInit() {
    this.mandateService.getAll().subscribe(mandators => this.dataSource = mandators);
  }

}
