<aside class="app-sidebar" [class.app-sidebar-toggled]="showSidebar">
  <ul class="app-menu">
    <li *ngIf="admin"><a routerLink="/dashboard" class="app-menu__item">Home</a></li>
    <li *ngIf="admin"><a routerLink="/beusers" class="app-menu__item">BE-User</a></li>
    <li *ngIf="admin"><a routerLink="/mandators" class="app-menu__item">Mandators</a></li>
    <li *ngIf="admin"><a routerLink="/customer" class="app-menu__item">Customers</a></li>
    <li *ngIf="admin"><hr></li>
    <li><a routerLink="/order" class="app-menu__item">Orders</a></li>
    <li *ngIf="admin"><hr></li>
    <li *ngIf="admin"><a routerLink="/projects" class="app-menu__item">Projects</a></li>
    <li *ngIf="admin"><a routerLink="/project-type" class="app-menu__item">Project Types</a></li>
    <li *ngIf="admin"><a routerLink="/project-standard" class="app-menu__item">Project Standards</a></li>
    <li *ngIf="admin"><a routerLink="/sdg" class="app-menu__item">SDGs</a></li>
    <li *ngIf="admin"><hr></li>
    <li *ngIf="admin"><a routerLink="/emission-factor/overview" class="app-menu__item">Emission Übersicht</a></li>
    <li *ngIf="admin"><a routerLink="/emission-factor/group" class="app-menu__item">Emission Groups</a></li>
    <li *ngIf="admin"><a routerLink="/emission-factor" class="app-menu__item">Emission Factors</a></li>
    <li *ngIf="admin"><hr></li>
    <li *ngIf="admin"><a routerLink="/preview" class="app-menu__item">Open Calculator Preview</a></li>
    <li *ngIf="admin"><a routerLink="/ecomlog" class="app-menu__item">Ecom Log</a></li>
  </ul>
</aside>
