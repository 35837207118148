<form [formGroup]="contactForm">
  <p-fieldset legend="Contact" i18n-legend="@@ContactFieldsetLabel" class="form-fieldset" [class.valid]="contactForm.valid" [class.invalid]="contactForm.invalid">
    <mat-form-field>
      <input matInput type="text" placeholder="Phone" i18n-placeholder="@@ContactPhonePlaceholder" formControlName="phone" maxlength="18">
    </mat-form-field>
    <mat-form-field>
      <input matInput type="text" placeholder="Fax" i18n-placeholder="@@ContactFaxPlaceholder" formControlName="fax" maxlength="18">
    </mat-form-field>
  </p-fieldset>
</form>
