import {Component, Inject, OnInit, Sanitizer, SecurityContext} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {CertificateConfig} from '../../model/certificateConfig';
import {CertificateService} from '../../services/certificate.service';
import {environment} from '../../../../environments/environment';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {FileService} from '../../../services/file.service';

@Component({
  selector: 'app-certificate-dialog',
  templateUrl: './certificate-dialog.component.html',
  styleUrls: ['./certificate-dialog.component.scss']
})
export class CertificateDialogComponent implements OnInit {
  pdfLink: SafeResourceUrl;
  loading = true;

  constructor(
    private service: CertificateService,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) private data: {language: string, certificate: CertificateConfig}
  ) { }

  ngOnInit() {
    this.loading = true;
    this.service.certificatePreview(this.data.language, this.data.certificate).subscribe(data => {
      this.service.get(`${data.file}.pdf`).subscribe(result => {
        this.loading = false;
        if (result.success) {
          const linkSource = 'data:application/pdf;base64,' + result.data;
          this.pdfLink = this.sanitizer.bypassSecurityTrustResourceUrl(linkSource);
        }
      });

    });
  }

}
