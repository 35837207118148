import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Company} from '../../model/company';
import {Subscription} from 'rxjs';
import {LayoutFormUpdateService} from '../../services/layout-form-update.service';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit, OnDestroy {
  companyForm: FormGroup;
  updateSubscription: Subscription;
  @Input() required = false;
  @Output() companyResult = new EventEmitter<Company>();

  constructor(
    private fb: FormBuilder,
    private service: LayoutFormUpdateService
  ) { }

  ngOnInit() {
    this.updateSubscription = this.service.company$.subscribe(company => {
      this.companyForm.patchValue(company);
    });

    if (this.required) {
      this.companyForm = this.fb.group({
        name: ['', [Validators.maxLength(100), Validators.required]],
        contact: ['', [Validators.maxLength(100), Validators.required]],
        vat: ['', [Validators.maxLength(12), Validators.required]],
      });
    } else {
      this.companyForm = this.fb.group({
        name: ['', [Validators.maxLength(100)]],
        contact: ['', [Validators.maxLength(100)]],
        vat: ['', [Validators.maxLength(12)]],
      });
    }

    this.companyForm.valueChanges.subscribe(data => {
      if (this.companyForm.valid) {
        this.companyResult.emit({...this.companyForm.value} as Company);
      } else {
        this.companyResult.emit(null);
      }
    });
  }

  ngOnDestroy() {
    this.updateSubscription.unsubscribe();
  }
}
