import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Mandator} from '../../mandate/model/mandator';
import {MandatorRaw} from '../../mandate/model/mandator-raw';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {MandatorFactory} from '../../mandate/model/mandator-factory';
import {FeUnit} from '../model/feUnit';

@Injectable({
  providedIn: 'root'
})
export class FeunitService {
  private servicePath = 'frontend_units';

  constructor(private http: HttpClient) {
  }

  getAll(): Observable<FeUnit[]> {
    return this.http
      .get<{ id: number, key: string, titleEn: string, titleDe: string }[]>(
        `${environment.basicUrl}${environment.apiPath}${this.servicePath}`
      )
      .pipe(
        map(feUnitRaw => feUnitRaw.map(raw => {
          return {
            id: raw.id,
            key: raw.key,
            en: raw.titleEn,
            de: raw.titleDe
          };
        })),
      );
  }

  add(feunitId: number, id: number, type: 'customer' | 'mandator'): Observable<any> {
    if (type === 'mandator') {
      return this.addMandator(feunitId, id);
    } else {
      return this.addCustomer(feunitId, id);
    }
  }

  remove(feunitId: number, id: number, type: 'customer' | 'mandator'): Observable<any> {
    if (type === 'mandator') {
      return this.removeMandator(feunitId, id);
    } else {
      return this.removeCustomer(feunitId, id);
    }
  }

  private addCustomer(feUnitId, customerId): Observable<FeUnit> {
    return this.http
      .post<any>(
        `${environment.basicUrl}${environment.apiPath}${this.servicePath}/customer_add`, {feUnitId, customerId}
      );
  }

  private addMandator(feUnitId, mandatorId): Observable<FeUnit> {
    return this.http
      .post<any>(
        `${environment.basicUrl}${environment.apiPath}${this.servicePath}/mandator_add`, {feUnitId, mandatorId}
      );
  }

  private removeCustomer(feUnitId, customerId): Observable<any> {
    return this.http
      .post<any>(
        `${environment.basicUrl}${environment.apiPath}${this.servicePath}/customer_remove`, {feUnitId, customerId}
      );
  }

  private removeMandator(feUnitId, mandatorId): Observable<any> {
    return this.http
      .post<any>(
        `${environment.basicUrl}${environment.apiPath}${this.servicePath}/mandator_remove`, {feUnitId, mandatorId}
      );
  }
}
