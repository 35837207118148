import {Component, Input, OnInit} from '@angular/core';
import {DataService} from '../../services/data.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @Input() showSidebar;
  admin: boolean;
  mandator: boolean;
  customer: boolean;

  constructor(private dataService: DataService) { }

  ngOnInit() {
    this.mandator = this.dataService.isMandator();
    this.customer = this.dataService.isCustomer();
    this.admin = this.dataService.isAdmin();
  }

}
