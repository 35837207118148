<form [formGroup]="certificateForm">
  <p-fieldset class="form-fieldset" [class.valid]="certificateForm.valid" [class.invalid]="certificateForm.invalid">
    <p-header>
      <span i18n="@@CertificateFieldsetLabel">Certificate Configuration</span>
      <button mat-raised-button class="btn-header" (click)="certificatePreview()" i18n="@@CertificatePreviewButton">Preview</button>
      <button mat-raised-button class="btn-header" (click)="certificateDefaults()" i18n="@@CertificateDefaultsButton">Reset</button>
    </p-header>

    <mat-form-field>
      <input matInput type="text" placeholder="name in certificate" i18n-placeholder="@@CertificateNamePlaceholder" formControlName="name" maxlength="150">
    </mat-form-field>

    <div class="form-control">
      <label for="inpLogoMain" i18n="@@CertificateLogoMainLabel" class="label-with-data">
        Main Logo
      </label>
      <div class="img-dropzone" (click)="fileLogoMain.click()" (drop)="dropLogoMain($event)" (dragover)="onDragOver($event)">
        <p *ngIf="!logoMainUrl || logoMainUrl === ''" i18n="@@DropzoneText">Drop the logo here using drag & drop</p>
        <div class="imagePreview" *ngIf="logoMainUrl && logoMainUrl !== ''">
          <i class="close pi pi-times" (click)="removeLogoMain($event)"></i>
          <img [src]="logoMainDisplayUrl" alt="customize customer logo" />
        </div>
      </div>
      <input hidden (change)="onLogoMainSelected($event)" #fileLogoMain type="file" id="inpLogoMain" />
    </div>

    <div class="form-control">
      <label for="inpLogoHeader" i18n="@@CertificateLogoHeaderLabel" class="label-with-data">
        Header Logo
      </label>
      <div class="img-dropzone" (click)="fileLogoHeader.click()" (drop)="dropLogoHeader($event)" (dragover)="onDragOver($event)">
        <p *ngIf="!logoHeaderUrl || logoHeaderUrl === ''" i18n="@@DropzoneText">Drop the logo here using drag & drop</p>
        <div class="imagePreview" *ngIf="logoHeaderUrl && logoHeaderUrl !== ''">
          <i class="close pi pi-times" (click)="removeLogoHeader($event)"></i>
          <img [src]="logoHeaderDisplayUrl" alt="customize customer logo" />
        </div>
      </div>
      <input hidden (change)="onLogoHeaderSelected($event)" #fileLogoHeader type="file" id="inpLogoHeader" />
    </div>

    <mat-form-field>
      <input matInput type="text" placeholder="intro text" i18n-placeholder="@@CertificateIntrotextPlaceholder" formControlName="introText" maxlength="350">
    </mat-form-field>

    <mat-form-field>
      <input matInput type="text" placeholder="sub text" i18n-placeholder="@@CertificateSubtextPlaceholder" formControlName="subText" maxlength="280">
    </mat-form-field>

    <mat-form-field>
      <input matInput type="text" placeholder="headline project overview" i18n-placeholder="@@CertificateProjectHeadlinePlaceholder" formControlName="projectHeadline" maxlength="120">
    </mat-form-field>

    <mat-form-field>
      <input matInput type="text" placeholder="issue location" i18n-placeholder="@@CertificateLocationPlaceholder" formControlName="location" maxlength="120">
    </mat-form-field>

    <mat-form-field>
      <input matInput type="text" placeholder="signee" i18n-placeholder="@@CertificateSigneeTextPlaceholder" formControlName="signeeText" maxlength="120">
    </mat-form-field>

    <div class="form-control">
      <label for="inpLogoSignee" i18n="@@CertificateLogoSigneeLabel" class="label-with-data">
        Signee Logo
      </label>
      <div class="img-dropzone" (click)="fileLogoSignee.click()" (drop)="dropLogoSignee($event)" (dragover)="onDragOver($event)">
        <p *ngIf="!logoSigneeUrl || logoSigneeUrl === ''" i18n="@@DropzoneText">Drop the logo here using drag & drop</p>
        <div class="imagePreview" *ngIf="logoSigneeUrl && logoSigneeUrl !== ''">
          <i class="close pi pi-times" (click)="removeLogoSignee($event)"></i>
          <img [src]="logoSigneeDisplayUrl" alt="customize customer logo" />
        </div>
      </div>
      <input hidden (change)="onLogoSigneeSelected($event)" #fileLogoSignee type="file" id="inpLogoSignee" />
    </div>

    <p-checkbox formControlName="showPublicId" label="Publish MFC-ID URL" i18n-label="@@CertificateShowPublicIdText" binary="true" class="mt-3 mb-3"></p-checkbox>

    <label for="selLabel" i18n="@@CertificateLabelDropdownLabel" class="label-with-data">
      MFC-Label Setting
    </label>
    <p-dropdown [options]="labelDropdowns" formControlName="labelId" selectId="selLabel"
                placeholder="Choose Label" i18n-placeholder="@@CertificateChooseLabelPlaceholder"></p-dropdown>

  </p-fieldset>
</form>
