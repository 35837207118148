import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {RouterModule} from '@angular/router';
import {TableComponent} from './table/table.component';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSelectModule} from '@angular/material/select';
import {MatTableModule} from '@angular/material/table';
import {CompanyComponent} from './forms/company/company.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AddressComponent} from './forms/address/address.component';
import {ContactComponent} from './forms/contact/contact.component';
import {PersonComponent} from './forms/person/person.component';
import {UserComponent} from './forms/user/user.component';
import {CertificateConfigComponent} from './forms/certificate-config/certificate-config.component';
import {InvoiceConfigComponent} from './forms/invoice-config/invoice-config.component';
import {CertificateDialogComponent} from './dialog/certificate-dialog/certificate-dialog.component';
import {InvoiceDialogComponent} from './dialog/invoice-dialog/invoice-dialog.component';
import {MatIconModule} from '@angular/material/icon';
import {FieldsetModule} from 'primeng/fieldset';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {SettingComponent} from './forms/setting/setting.component';
import {CheckboxModule} from 'primeng/checkbox';
import {InputTextModule} from 'primeng/inputtext';
import {TableModule} from 'primeng/table';
import {DropdownModule} from 'primeng/dropdown';
import {PasswordStrengthComponent} from './util/password-strength/password-strength.component';
import {TooltipModule} from 'primeng/tooltip';
import { FeUnitComponent } from './forms/fe-unit/fe-unit.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    TableComponent,
    CompanyComponent,
    AddressComponent,
    ContactComponent,
    PersonComponent,
    UserComponent,
    CertificateConfigComponent,
    InvoiceConfigComponent,
    CertificateDialogComponent,
    InvoiceDialogComponent,
    SettingComponent,
    PasswordStrengthComponent,
    FeUnitComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatTableModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatCheckboxModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    FieldsetModule,
    CheckboxModule,
    InputTextModule,
    TableModule,
    DropdownModule,
    TooltipModule,
    FormsModule
  ],
    exports: [
        HeaderComponent,
        SidebarComponent,
        FooterComponent,
        TableComponent,
        CompanyComponent,
        AddressComponent,
        ContactComponent,
        UserComponent,
        PersonComponent,
        CertificateConfigComponent,
        InvoiceConfigComponent,
        SettingComponent,
        PasswordStrengthComponent,
        FeUnitComponent
    ],
  entryComponents: [
    CertificateDialogComponent,
    InvoiceDialogComponent
  ]
})
export class LayoutModule {
}
