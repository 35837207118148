import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {CertificateConfig} from '../model/certificateConfig';
import {Label} from '../model/label';

@Injectable({
  providedIn: 'root'
})
export class CertificateService {
  private servicePath = 'projects';

  constructor(private http: HttpClient) {
  }

  certificatePreview(language: string, certificate: CertificateConfig): Observable<any> {
    const formData = new FormData();
    formData.append('language', language);
    formData.append('certificateName', certificate?.name);
    formData.append('certificateIntroText', certificate?.introText);
    formData.append('certificateSubText', certificate?.subText);
    formData.append('certificateProjectHeadline', certificate?.projectHeadline);
    formData.append('certificateLocation', certificate?.location);
    formData.append('certificateSigneeText', certificate?.signeeText);
    formData.append('certificateShowPublicId', certificate?.showPublicId ? 'true' : 'false');
    formData.append('certificateLabelId', certificate?.labelId.toString());
    formData.append('fileMainLogo', certificate?.logoMain);
    formData.append('fileCustomerLogo', certificate?.logoHeader);
    formData.append('fileSigneeLogo', certificate?.logoSignee);

    return this.http
      .post(
        `${environment.basicUrl}${environment.apiPath}certificate/preview`,
        formData
      );
  }

  get(filepath: string): Observable<any> {
    return this.http.post(`${environment.basicUrl}pdf`, {filepath});
  }

  defaults(language: 'en' | 'de'): Observable<{ data: { id: number, labelId: number, name: string, introText: string, subText: string, projectHeadline: string, location: string, signeeText: string, logoHeaderPath: string, signeeLogoPath: string }, header: string, signee: string }> {
    return this.http
      .get<any>(
        `${environment.basicUrl}${environment.apiPath}certificate/defaults/${language}`
      );
  }

  getAllLabels(): Observable<Label[]> {
    return this.http.get<Label[]>(`${environment.basicUrl}${environment.apiPath}labels`);
  }
}
