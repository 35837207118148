import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {Setting} from '../../model/setting';
import {LayoutFormUpdateService} from '../../services/layout-form-update.service';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss']
})
export class SettingComponent implements OnInit, OnDestroy {

  settingForm: FormGroup;
  updateSubscription: Subscription;
  @Output() settingResult = new EventEmitter<Setting>();

  constructor(private fb: FormBuilder, private service: LayoutFormUpdateService) {
  }

  ngOnInit(): void {
    this.updateSubscription = this.service.setting$.subscribe(setting => {
      this.settingForm.patchValue({...setting});
    });

    this.settingForm = this.fb.group({
      termOfPayment: ['', [Validators.required]],
      instantInvoice: [false],
      chargeVat: [false],
      vatRate: ['', [Validators.required]],
      fee: [0, [Validators.required]],
    });

    this.settingForm.valueChanges.subscribe(data => {
      if (this.settingForm.valid) {
        this.settingResult.emit({
          ...this.settingForm.value
        } as Setting);
      } else {
        this.settingResult.emit(null);
      }
    });
  }

  ngOnDestroy(): void {
    this.updateSubscription.unsubscribe();
  }

}
