import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActionService {

  private saveSource = new Subject<boolean>();
  save$ = this.saveSource.asObservable();
  private errorSource = new Subject<boolean>();
  error$ = this.errorSource.asObservable();

  showSaveDialog() {
    this.saveSource.next(true);
  }
  hideSaveDialog() {
    this.saveSource.next(false);
  }
  showError() {
    this.hideSaveDialog();
    this.errorSource.next(true);
  }
}
