<form [formGroup]="addressForm">
  <p-fieldset legend="Address" i18n-legend="@@AddressFieldsetLabel" class="form-fieldset" [class.valid]="addressForm.valid" [class.invalid]="!addressForm.valid">
    <div class="ui-g">
      <mat-form-field class="ui-g-12">
        <input matInput type="text" placeholder="Street 1" i18n-placeholder="@@AddressStreet1Placeholder" formControlName="street1" maxlength="100">
      </mat-form-field>

      <mat-form-field class="ui-g-12">
        <input matInput type="text" placeholder="Street 2" i18n-placeholder="@@AddressStreet2Placeholder" formControlName="street2" maxlength="100">
      </mat-form-field>

      <mat-form-field class="ui-g-12 ui-md-4">
        <input matInput type="text" placeholder="Zip" i18n-placeholder="@@AddressZipPlaceholder" formControlName="zip" maxlength="8">
      </mat-form-field>
      <mat-form-field class="ui-g-12 ui-md-8">
        <input matInput type="text" placeholder="City" i18n-placeholder="@@AddressCityPlaceholder" formControlName="city" maxlength="100">
      </mat-form-field>

      <div class="ui-g-12 address-dropdown-wrapper">
        <label for="selCountry" i18n="@@AddressCountryLabel">Country</label>
        <p-dropdown [options]="countries" inputId="selCountry" optionLabel="name" formControlName="country" placeholder="Select a country" i18n-placeholder="@@AddressCountryPlaceholder"></p-dropdown>
      </div>
    </div>
  </p-fieldset>
</form>
