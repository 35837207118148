import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {User} from '../../model/user';
import {Subscription} from 'rxjs';
import {LayoutFormUpdateService} from '../../services/layout-form-update.service';
import {SelectItem} from 'primeng/api';
import {DataService} from '../../../services/data.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit, OnDestroy {
  userToEdit: User = null;
  userForm: FormGroup;
  passwordIsValid = false;
  updateSubscription: Subscription;
  languages: SelectItem[] = [];
  valid = false;

  @Input() showLanguage = false;
  @Output() userResult = new EventEmitter<User>();
  @Output() languageChange = new EventEmitter<string>();

  constructor(
    private dataService: DataService,
    private fb: FormBuilder,
    private service: LayoutFormUpdateService
  ) {
  }

  ngOnInit() {
    this.valid = false;
    this.languages = [
      {
        label: this.dataService.getLocale() === 'de' ? 'Deutsch' : 'German',
        value: 'de'
      },
      {
        label: this.dataService.getLocale() === 'de' ? 'Englisch' : 'English',
        value: 'en'
      }
    ];

    this.updateSubscription = this.service.user$.subscribe(user => {
      this.userToEdit = user;
      this.userForm.patchValue(user);
    });

    this.userForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', [Validators.maxLength(50)]],
      language: ['']
    });

    this.userForm.valueChanges.subscribe(data => {
      setTimeout(() => {
        if (this.userForm.valid && this.passwordIsValid) {
          this.valid = true;
          this.userResult.emit({...this.userToEdit, ...this.userForm.value} as User);
        } else {
          if (this.userToEdit != null
            && this.userForm.get('username').value.length > 0
            && this.userForm.get('password').value.length === 0
          ) {
            this.valid = true;
            this.userToEdit.username = this.userForm.get('username').value;
            this.userResult.emit(this.userToEdit);
          } else {
            this.valid = false;
            this.userResult.emit(null);
          }
        }
      }, 100);
    });

    this.userForm.get('language').valueChanges.subscribe(language => this.languageChange.emit(language));
  }

  ngOnDestroy() {
    this.updateSubscription.unsubscribe();
  }

  passwordValid(event) {
    this.passwordIsValid = event;
  }
}
