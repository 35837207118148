import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {Subscription} from 'rxjs';
import {Company} from '../../model/company';
import {FeUnit} from '../../model/feUnit';
import {LayoutFormUpdateService} from '../../services/layout-form-update.service';
import {FeunitService} from '../../services/feunit.service';
import {DataService} from '../../../services/data.service';
import {ActionService} from '../../../services/action.service';

@Component({
  selector: 'app-fe-unit',
  templateUrl: './fe-unit.component.html',
  styleUrls: ['./fe-unit.component.scss']
})
export class FeUnitComponent implements OnInit, OnChanges, OnDestroy {
  language: 'en' | 'de';
  feUnitSubscription: Subscription;
  allFeUnits: FeUnit[] = [];
  selectedIds: number[] = [];
  @Input() id: number;
  @Input() type: 'customer' | 'mandator';
  @Input() currentFeUnits: FeUnit[];

  constructor(
    private layoutService: LayoutFormUpdateService,
    private feUnitService: FeunitService,
    private dataService: DataService,
    private actionService: ActionService
  ) {
  }

  ngOnInit(): void {
    this.language = this.dataService.getLocale();
    this.feUnitSubscription = this.feUnitService.getAll().subscribe(feUnits => {
      this.allFeUnits = feUnits;
    });
  }

  ngOnChanges() {
    this.selectedIds = this.currentFeUnits.map(feUnit => feUnit.id);
  }

  ngOnDestroy() {
    this.feUnitSubscription.unsubscribe();
  }

  changeFeUnit(checked, feUnitId) {
    const index = this.currentFeUnits.findIndex(feUnit => feUnit.id === feUnitId);
    if (checked) {
      if (index === -1) {
        this.actionService.showSaveDialog();
        this.feUnitService.add(feUnitId, this.id, this.type).subscribe(
          result => {
            this.currentFeUnits.push(this.allFeUnits.find(feUnit => feUnit.id === feUnitId));
            this.actionService.hideSaveDialog();
          },
          err => this.actionService.showError()
        );
      }
    } else {
      if (index > -1) {
        this.actionService.showSaveDialog();
        this.feUnitService.remove(feUnitId, this.id, this.type).subscribe(
          result => {
            this.currentFeUnits.splice(index, 1);
            this.actionService.hideSaveDialog();
          },
          err => this.actionService.showError()
        );
      }
    }
  }
}
