import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private locale = 'en';
  private userid: string;
  private username: string;
  private roles: string[];

  setLocale(locale: string) {
    this.locale = locale;
  }

  getLocale(): 'de' | 'en' {
    return this.locale.includes('de') ? 'de' : 'en';
  }

  setUsername(username: string) {
    this.username = username;
  }

  getUserId() {
    return this.userid;
  }

  setUserId(userId: string) {
    this.userid = userId;
  }

  getUsername(): string {
    return this.username || '';
  }

  setRoles(roles: string[]) {
    this.roles = roles;
  }

  isCustomer(): boolean {
    return this.roles.includes('ROLE_FC_CUSTOMER');
  }

  isMandator(): boolean {
    return this.roles.includes('ROLE_FC_MANDATOR');
  }

  isAdmin(): boolean {
    return this.roles.includes('ROLE_FC_ADMIN');
  }

}
