import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {ActionService} from './services/action.service';
import {Subscription} from 'rxjs';
import {MessageService} from 'primeng/api';
import {AuthenticationService} from './services/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MessageService]
})
export class AppComponent implements OnInit, OnDestroy {
  showHeaderSection = false;
  showSidebarSection = false;
  showFooterSection = false;
  isSidebarVisible = false;

  displaySaveDialog = false;
  displayTokenExpiredDialog = false;
  private saveDialogSubscription: Subscription;
  private showErrorSubscription: Subscription;
  private expirationDialogSubscription: Subscription;


  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private actionService: ActionService,
    private messageService: MessageService,
    private authService: AuthenticationService
  ) {
  }

  ngOnInit() {
    this.expirationDialogSubscription = this.authService.expirationToken$.subscribe(result => this.displayTokenExpiredDialog = true);
    this.saveDialogSubscription = this.actionService.save$.subscribe(result => this.displaySaveDialog = result);
    this.showErrorSubscription = this.actionService.error$.subscribe(result => this.messageService.add({
      severity: 'error',
      summary: 'Error Message',
      detail: 'Validation failed'
    }));

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.showHeaderSection = this.activatedRoute.firstChild.snapshot.data.showHeader !== false;
        this.showSidebarSection = this.activatedRoute.firstChild.snapshot.data.showSidebar !== false;
        this.showFooterSection = this.activatedRoute.firstChild.snapshot.data.showFooter !== false;
      }
    });
  }

  ngOnDestroy() {
    this.saveDialogSubscription.unsubscribe();
    this.showErrorSubscription.unsubscribe();
    this.expirationDialogSubscription.unsubscribe();
  }

  reloadLogin() {
    this.authService.logout();
    this.displayTokenExpiredDialog = false;
    this.router.navigateByUrl('/login');
  }
}
