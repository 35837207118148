import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthenticationService} from './authentication.service';
import {tap} from 'rxjs/operators';
import {environment} from '../../environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthenticationService) {
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let newHeaders = null;

    if (this.authService.isLoggedIn()) {
      newHeaders = {
        Accept: 'application/json',
        Authorization: `Bearer ${this.authService.token}`
      };
    } else {
      newHeaders = {
        Accept: 'application/json'
      };
    }

    const newRequest = req.clone({
      setHeaders: {...newHeaders}
    });

    return next
      .handle(newRequest)
      .pipe(
        tap(() => {
          },
          (err: any) => {
            if (err instanceof HttpErrorResponse) {
              if (err.status !== 401) {
                return;
              }

              if ('Expired JWT Token' === err.error.message) {
                this.authService.tokenIsExpired();
              }
            }
          })
      );
  }
}
