<header class="app-header">
  <a class="app-sidebar__toggle d-md-none" data-toggle="sidebar" aria-label="Hide Sidebar" (click)="changeSidebar()"><span
    class="fa fa-bars"></span></a>
  <a href="/" class="app-header__logo">
    <img src="assets/img/logo_header.svg" alt="Logo">
  </a>
  <button class="app-header__logout" (click)="doLogout()">
    <span class="fas fa-sign-out-alt"></span>
  </button>
</header>
