import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Customer} from '../../customer/model/customer';
import {CustomerRaw} from '../../customer/model/customer-raw';
import {map} from 'rxjs/operators';
import {CustomerFactory} from '../../customer/model/customer-factory';
import {Mandator} from '../model/mandator';
import {MandatorRaw} from '../model/mandator-raw';
import {MandatorFactory} from '../model/mandator-factory';

@Injectable({
  providedIn: 'root'
})
export class MandateService {
  private servicePath = 'mandators';

  constructor(private http: HttpClient) { }

  getAll(): Observable<Mandator[]> {
    return this.http
      .get<MandatorRaw[]>(`${environment.basicUrl}${environment.apiPath}${this.servicePath}`)
      .pipe(
        map(mandatorsRaw => mandatorsRaw.map(raw => MandatorFactory.fromRaw(raw))),
      );
  }

  getSingle(id: string): Observable<Mandator> {
    return this.http
      .get<MandatorRaw>(`${environment.basicUrl}${environment.apiPath}${this.servicePath}/${id}`)
      .pipe(
        map(mandatorRaw => MandatorFactory.fromRaw(mandatorRaw)),
      );
  }

  save(mandator: Mandator): Observable<Mandator> {
    return (!mandator.id || mandator.id === 0) ? this.create(mandator) : this.update(mandator);
  }

  private create(mandator: Mandator): Observable<Mandator> {
    delete mandator.id;
    return this.http
      .post<MandatorRaw>(`${environment.basicUrl}${environment.apiPath}${this.servicePath}`, MandatorFactory.toRaw(mandator))
      .pipe(
        map(mandatorRaw => MandatorFactory.fromRaw(mandatorRaw)),
      );
  }

  private update(mandator: Mandator): Observable<Mandator> {
    return this.http
      .put<MandatorRaw>(
        `${environment.basicUrl}${environment.apiPath}${this.servicePath}/${mandator.id}`,
        MandatorFactory.toRaw(mandator))
      .pipe(
        map(mandatorRaw => MandatorFactory.fromRaw(mandatorRaw)),
      );
  }
}
