import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
import {Person} from '../model/person';
import {Address} from '../model/address';
import {Contact} from '../model/contact';
import {Company} from '../model/company';
import {User} from '../model/user';
import {InvoiceConfig} from '../model/invoiceConfig';
import {CertificateConfig} from '../model/certificateConfig';
import {Setting} from '../model/setting';

@Injectable({
  providedIn: 'root'
})
export class LayoutFormUpdateService {

  private userSource = new Subject<User>();
  private personSource = new Subject<Person>();
  private companySource = new Subject<Company>();
  private addressSource = new Subject<Address>();
  private contactSource = new Subject<Contact>();
  private settingSource = new Subject<Setting>();
  private certificateSource = new Subject<CertificateConfig>();
  private invoiceSource = new Subject<InvoiceConfig>();
  user$ = this.userSource.asObservable();
  person$ = this.personSource.asObservable();
  company$ = this.companySource.asObservable();
  address$ = this.addressSource.asObservable();
  contact$ = this.contactSource.asObservable();
  setting$ = this.settingSource.asObservable();
  certificate$ = this.certificateSource.asObservable();
  invoice$ = this.invoiceSource.asObservable();

  updateUser(user: User) {
    this.userSource.next(user);
  }
  updatePerson(person: Person) {
    this.personSource.next(person);
  }
  updateCompany(company: Company) {
    this.companySource.next(company);
  }
  updateAddress(address: Address) {
    this.addressSource.next(address);
  }
  updateContact(contact: Contact) {
    this.contactSource.next(contact);
  }
  updateSetting(setting: Setting) {
    this.settingSource.next(setting);
  }
  updateCertificate(certificate: CertificateConfig) {
    this.certificateSource.next(certificate);
  }
  updateInvoice(invoice: InvoiceConfig) {
    this.invoiceSource.next(invoice);
  }
}
