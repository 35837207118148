import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {MandateListComponent} from './mandate-list/mandate-list.component';
import {MandateDetailComponent} from './mandate-detail/mandate-detail.component';

const routes: Routes = [
  {path: '', component: MandateListComponent},
  {path: 'edit/:id', component: MandateDetailComponent},
  {path: 'add', component: MandateDetailComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MandateRoutingModule {
}
