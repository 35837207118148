<p-table #fctable class="fc-table {{extraClass}}" [columns]="config.columns" [value]="dataSource" [rowHover]="true"
         [resizableColumns]="true" [paginator]="true" [rows]="rows" [rowsPerPageOptions]="[10,25,50, 100]">
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns" [pSortableColumn]="col.value" pResizableColumn>
        {{col.title}}
        <p-sortIcon [field]="col.value"></p-sortIcon>
      </th>

      <ng-container *ngIf="editable">
        <th style="width: 3.5rem;">Edit</th>
      </ng-container>

      <ng-container *ngFor="let action of listActions">
        <th style="width: 3.5rem;">
          <span *ngIf="language === 'de'">{{action.labelDe}}</span>
          <span *ngIf="language === 'en'">{{action.labelEn}}</span>
        </th>
      </ng-container>
    </tr>

    <tr>
      <th *ngFor="let col of columns">
        <input class="ui-column-filter" pInputText type="text"
               (input)="fctable.filter($event.target.value, col.value, 'contains')">
      </th>

      <ng-container *ngIf="editable">
        <th></th>
      </ng-container>

      <ng-container *ngFor="let action of listActions">
        <th></th>
      </ng-container>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr class="{{ rowClassProperty ? getRowClassValue(rowData) : '' }}">
      <td *ngFor="let col of columns" class="ui-resizable-column">
        <ng-container *ngIf="col.type === 'check'; else defaultColumn">
          <mat-icon *ngIf="rowData[col.value]">check</mat-icon>
          <mat-icon *ngIf="!rowData[col.value]">close</mat-icon>
        </ng-container>
        <ng-template #defaultColumn>
          <ng-container *ngIf="col.type === 'method'; else dateColumn">
            {{rowData[col.value]()}}
          </ng-container>
        </ng-template>
        <ng-template #dateColumn>
          <ng-container *ngIf="col.type === 'date'; else output">
            {{rowData[col.value] | date:'dd.MM.yyyy HH:mm:ss'}}
          </ng-container>
        </ng-template>
        <ng-template #output>
          {{rowData[col.value]}}
        </ng-template>
      </td>

      <ng-container *ngIf="editable">
        <td>
          <a [routerLink]="[path, 'edit', rowData.id]">
            <mat-icon>edit</mat-icon>
          </a>
        </td>
      </ng-container>

      <ng-container *ngFor="let action of listActions">
        <td>
          <a *ngIf="action.link && !action.link.startsWith('#')" [routerLink]="[path, action.link, rowData.id]" class="{{action.class || ''}}">
            <mat-icon>{{action.icon || 'list'}}</mat-icon>
          </a>
          <span *ngIf="action.link && action.link.startsWith('#') && rowData['type'] === 'O' && rowData['paymentState'].toLowerCase() !== 'cancelled'" (click)="doOutputListaction(action.link, rowData.id)" class="listaction {{action.class || ''}}">
            <mat-icon>{{action.icon || 'list'}}</mat-icon>
          </span>
        </td>
      </ng-container>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <tr>
      <td [attr.colspan]="columns.length" style="text-align:left">Found no results</td>
    </tr>
  </ng-template>
</p-table>

<div class="gap"></div>
