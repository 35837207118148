import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {retry} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  private servicePath = 'project_assets';
  private projectFactSheetPath = 'project_fact_sheets';
  private projectImagePath = 'project_images';
  private certificatePath = 'certificate_main_logos';
  private certificateHeaderPath = 'certificate_header_logos';
  private certificateSigneePath = 'certificate_signee_logos';

  constructor(private http: HttpClient) {
  }

  get(filepath: string): Observable<any> {
    return this.http.post(`${environment.basicUrl}asset`, {filepath: filepath});
  }

  // upload(fileData): Observable<any> {
  //   const formData = new FormData();
  //   formData.append('file', fileData);
  //   return this.http.post(`${environment.fileUrl}${this.servicePath}`, formData);
  // }

  uploadProjectStandard(fileData): Observable<any> {
    const formData = new FormData();
    formData.append('file', fileData);
    return this.http
      .post(`${environment.basicUrl}${environment.apiPath}project_standard_file`, formData);
  }


  uploadProjectFactSheet(fileData): Observable<any> {
    const formData = new FormData();
    formData.append('file', fileData);
    return this.http
      .post(`${environment.basicUrl}${environment.apiPath}project_factsheet_file`, formData);
  }

  uploadProjectImage(fileData): Observable<any> {
    const formData = new FormData();
    formData.append('file', fileData);
    return this.http
      .post(`${environment.basicUrl}${environment.apiPath}project_image_file`, formData);
  }

  uploadMainLogo(fileData): Observable<any> {
    const formData = new FormData();
    formData.append('file', fileData);
    return this.http
      .post(`${environment.basicUrl}${environment.apiPath}certificate_main`, formData);
  }

  uploadHeaderLogo(fileData): Observable<any> {
    const formData = new FormData();
    formData.append('file', fileData);
    return this.http
      .post(`${environment.basicUrl}${environment.apiPath}certificate_header`, formData);
  }

  uploadSigneeLogo(fileData): Observable<any> {
    const formData = new FormData();
    formData.append('file', fileData);
    return this.http
      .post(`${environment.basicUrl}${environment.apiPath}certificate_signee`, formData);
  }
}
