import {Company} from '../../layout/model/company';
import {Address} from '../../layout/model/address';
import {Contact} from '../../layout/model/contact';
import {User} from '../../layout/model/user';
import {Person} from '../../layout/model/person';
import {CertificateConfig} from '../../layout/model/certificateConfig';
import {InvoiceConfig} from '../../layout/model/invoiceConfig';
import {Setting} from './setting';

export class UtilFactory {

  static userFromRaw(raw, language?: string): User {
    return {
      id: raw.id || null,
      username: raw.username,
      password: '',
      language: language || 'en'
    };
  }

  static personFromRaw(raw): Person {
    return {
      ...raw,
      id: raw.id || null
    };
  }

  static companyFromRaw(raw): Company {
    return {
      ...raw,
      id: raw.id || null,
    };
  }

  static addressFromRaw(raw): Address {
    return {
      ...raw,
      id: raw.id || null,
      countryCode: raw.country
    };
  }

  static contactFromRaw(raw): Contact {
    return {
      ...raw,
      id: raw.id || null,
    };
  }

  static settingFromRaw(raw): Setting {
    return {
      ...raw,
      id: raw.id || null,
    };
  }

  static certificateConfigFromRaw(raw): CertificateConfig {
    return {
      ...raw,
      id: raw.id || null,
    };
  }

  static invoiceConfigFromRaw(raw): InvoiceConfig {
    return {
      ...raw,
      id: raw.id || null,
    };
  }

  static get defaultUser(): User {
    return {id: null, username: '', password: '', language: ''};
  }

  static get defaultPerson(): Person {
    return {id: null, salutation: '', firstname: '', lastname: '', email: ''};
  }

  static get defaultCompany(): Company {
    return {id: null, name: '', contact: '', vat: ''};
  }

  static get defaultAddress(): Address {
    return {id: null, street1: '', street2: '', zip: '', city: '', countryCode: ''};
  }

  static get defaultContact(): Contact {
    return {id: null, phone: '', fax: ''};
  }

  static get defaultSetting(): Setting {
    return {id: null, termOfPayment: null, instantInvoice: false, chargeVat: false, vatRate: null, fee: 0};
  }

  static get defaultCertificateConfig(): CertificateConfig {
    return {
      id: -1,
      name: '',
      introText: '',
      subText: '',
      projectHeadline: '',
      location: '',
      signeeText: '',
      showPublicId: false,
      signeeLogoPath: '',
      logoHeaderPath: '',
      logoMainPath: '',
      labelId: 1
    };
  }

  static get defaultInvoiceConfig(): InvoiceConfig {
    return {id: -1, senderInformation: '', introText: '', footerRow1: '', footerRow2: '', footerRow3: '', footerRow4: ''};
  }
}
