<p-dialog [(visible)]="displayTokenExpiredDialog" [modal]="true" [baseZIndex]="10000" [style]="{width: '50vw'}"
          [draggable]="false" [resizable]="false" [closeOnEscape]="false" [closable]="false" [showHeader]="false">
  <p i18n="@@AppDialogTokenExpirationText">Your login access for the administration backend has expired. Please log in again.</p>
  <p-footer>
    <button type="button" pButton icon="pi pi-check" (click)="reloadLogin()" label="back to login" i18n-label="@@AppDialogTokenExpirationButton"></button>
  </p-footer>
</p-dialog>

<p-toast position="top-right" [baseZIndex]="5000">
  <ng-template let-message pTemplate="message">
    <div class="ui-toast-message-text-content">
      <a class="ui-toast-close-icon pi pi-times" tabindex="0" style=""></a>
      <span class="ui-toast-icon pi pi-times-circle" style=""></span>
      <div class="ui-toast-summary" i18n="@@ToastGlobalErrorTitle">CAUTION! An error has occurred.</div>
      <div class="ui-toast-detail" i18n="@@ToastGlobalErrorText">Please repeat your entry.</div>
    </div>
  </ng-template>
</p-toast>

<p-dialog
  [(visible)]="displaySaveDialog"
  [resizable]="false"
  [closeOnEscape]="false"
  [closable]="false"
  [modal]="true"
  class="fc-dialog dialog-without-header"
>
  <i class="pi pi-spinner pi-spin"></i>
  <span i18n="@@SaveDialogMessage">Your Data is being saved.</span>
</p-dialog>

<app-header *ngIf="showHeaderSection" (showSidebarChanged)="this.isSidebarVisible = $event"></app-header>

<app-sidebar *ngIf="showSidebarSection" [showSidebar]="this.isSidebarVisible"></app-sidebar>

<main class="app-content" [class.no-header]="!showHeaderSection" [class.no-sidebar]="!showSidebarSection">

  <router-outlet></router-outlet>

  <!--  <div class="app-title">asd</div>-->
  <!--  <div class="main">-->
  <!--    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium amet consequatur dicta-->
  <!--      dignissimos error eveniet facilis hic iste laudantium maxime praesentium quas quisquam-->
  <!--      reiciendis sapiente sint, tenetur veniam vero voluptatem?</p>-->
  <!--    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium amet consequatur dicta-->
  <!--      dignissimos error eveniet facilis hic iste laudantium maxime praesentium quas quisquam-->
  <!--      reiciendis sapiente sint, tenetur veniam vero voluptatem?</p>-->
  <!--  </div>-->
  <!--  <div class="container">-->
  <!--    <div class="row">-->
  <!--      <div class="col-md-6">-->
  <!--        <div class="card">-->
  <!--          <div class="card-header">-->
  <!--            Featured-->
  <!--          </div>-->
  <!--          <div class="card-body">-->
  <!--            <h5 class="card-title">Special title treatment</h5>-->
  <!--            <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>-->
  <!--            <a href="#" class="btn btn-primary">Go somewhere</a>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--      <div class="col-md-6">-->
  <!--        <div class="card">-->
  <!--          <div class="card-header">-->
  <!--            Featured-->
  <!--          </div>-->
  <!--          <div class="card-body">-->
  <!--            <h5 class="card-title">Special title treatment</h5>-->
  <!--            <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>-->
  <!--            <a href="#" class="btn btn-primary">Go somewhere</a>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
</main>


<app-footer *ngIf="showFooterSection" [class.no-sidebar]="!showSidebarSection"></app-footer>
