import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Person} from '../../model/person';
import {LayoutFormUpdateService} from '../../services/layout-form-update.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-person',
  templateUrl: './person.component.html',
  styleUrls: ['./person.component.scss']
})
export class PersonComponent implements OnInit, OnDestroy {
  personForm: FormGroup;
  updateSubscription: Subscription;
  @Output() personResult = new EventEmitter<Person>();


  salutations: {value: string, labelEn: string, labelDe: string}[] = [
    {value: 'f', labelEn: 'Ms', labelDe: 'Frau'},
    {value: 'm', labelEn: 'Mr', labelDe: 'Herr'}
    // {value: 'u', labelEn: 'other', labelDe: 'sonst'},
  ];

  constructor(
    private fb: FormBuilder,
    private service: LayoutFormUpdateService
  ) { }

  ngOnInit() {
    this.updateSubscription = this.service.person$.subscribe(person => {
      this.personForm.patchValue({
        ...person,
        salutation: person.salutation ? this.salutations.find(salutation => salutation.value === person.salutation) : '',
        mail: person.email
      });
    });

    this.personForm = this.fb.group({
      salutation: [this.salutations[0], [Validators.required]],
      firstname: ['', [Validators.maxLength(50), Validators.required]],
      lastname: ['', [Validators.maxLength(50), Validators.required]],
      mail: ['', [Validators.maxLength(255), Validators.required]]
    });

    this.personForm.valueChanges.subscribe(data => {
      if (this.personForm.valid) {
        this.personResult.emit({
          ...this.personForm.value,
          salutation: this.personForm.get('salutation').value ? this.personForm.get('salutation').value.value : '',
          email: this.personForm.get('mail').value
        } as Person);
      } else {
        this.personResult.emit(null);
      }
    });
  }

  ngOnDestroy() {
    this.updateSubscription.unsubscribe();
  }
}
