<div *ngIf="loading">
  Lade PDF...
</div>

<div *ngIf="!loading">
  <object
    [data]="pdfLink"
    type="application/pdf"
    width="100%"
    height="100%">
    <p>Darstellung des PDFs nicht möglich.</p>
    <a [href]="pdfLink">PDF herunterladen</a>
  </object>
</div>
