import {MandatorRaw} from './mandator-raw';
import {Mandator} from './mandator';
import {UtilFactory} from '../../layout/model/util-factory';

export class MandatorFactory {
  static fromRaw(raw: MandatorRaw): Mandator {
    const mandator = new Mandator();
    mandator.id = raw.id;

    mandator.user = UtilFactory.userFromRaw(raw, raw.setting.language);
    mandator.person = UtilFactory.personFromRaw(raw.person);
    mandator.company = UtilFactory.companyFromRaw(raw.company);
    mandator.address = UtilFactory.addressFromRaw(raw.address);
    mandator.contact = UtilFactory.contactFromRaw(raw.contact);
    mandator.setting = UtilFactory.settingFromRaw(raw.setting);
    mandator.certificateConfig = UtilFactory.certificateConfigFromRaw(raw.certificateConfig);
    mandator.invoiceConfig = UtilFactory.invoiceConfigFromRaw(raw.invoiceConfig);
    mandator.units = raw.units ? raw.units.map(unit => {
      return {
        id: unit.id,
        key: unit.key,
        en: unit.titleEn,
        de: unit.titleDe
      };
    }) : [];
    return mandator;
  }

  static toRaw(mandator: Mandator): MandatorRaw {
    const feUnits = mandator.units ? mandator.units.map(feUnit => {
      return {
        id: feUnit.id,
        key: feUnit.key,
        titleEn: feUnit.en,
        titleDe: feUnit.de
      };
    }) : [];

    const raw: MandatorRaw = {
      id: mandator.id,
      username: mandator.user.username,
      person: {
        id: mandator.person.id,
        salutation: mandator.person.salutation,
        firstname: mandator.person.firstname,
        lastname: mandator.person.lastname,
        email: mandator.person.email
      },
      company: {
        id: mandator.company.id,
        name: mandator.company.name,
        contact: mandator.company.contact,
        vat: mandator.company.vat
      },
      address: {
        id: mandator.address.id,
        street1: mandator.address.street1,
        street2: mandator.address.street2,
        zip: mandator.address.zip,
        city: mandator.address.city,
        country: mandator.address.countryCode
      },
      contact: {
        id: mandator.contact.id,
        phone: mandator.contact.phone,
        fax: mandator.contact.fax
      },
      setting: {
        id: mandator.setting.id,
        termOfPayment: mandator.setting.termOfPayment,
        instantInvoice: mandator.setting.instantInvoice,
        chargeVat: mandator.setting.chargeVat,
        vatRate: mandator.setting.vatRate,
        language: mandator.user.language
      },
      certificateConfig: {
        id: mandator.certificateConfig.id,
        name: mandator.certificateConfig.name,
        logoMainPath: mandator.certificateConfig.logoMainPath,
        logoHeaderPath: mandator.certificateConfig.logoHeaderPath,
        introText: mandator.certificateConfig.introText,
        subText: mandator.certificateConfig.subText,
        projectHeadline: mandator.certificateConfig.projectHeadline,
        location: mandator.certificateConfig.location,
        signeeText: mandator.certificateConfig.signeeText,
        signeeLogoPath: mandator.certificateConfig.signeeLogoPath,
        showPublicId: mandator.certificateConfig.showPublicId,
        labelId: mandator.certificateConfig.labelId
      },
      invoiceConfig: {
        senderInformation: mandator.invoiceConfig.senderInformation,
        introText: mandator.invoiceConfig.introText,
        footerRow1: mandator.invoiceConfig.footerRow1,
        footerRow2: mandator.invoiceConfig.footerRow2,
        footerRow3: mandator.invoiceConfig.footerRow3,
        footerRow4: mandator.invoiceConfig.footerRow4
      },
      units: feUnits
    };

    if (mandator.user.password.length > 0) {
      raw.password = mandator.user.password;
    }
    return raw;
  }

}
