import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {CertificateConfig} from '../../model/certificateConfig';
import {LayoutFormUpdateService} from '../../services/layout-form-update.service';
import {InvoiceConfig} from '../../model/invoiceConfig';
import {CertificateDialogComponent} from '../../dialog/certificate-dialog/certificate-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import {InvoiceDialogComponent} from '../../dialog/invoice-dialog/invoice-dialog.component';
import {InvoiceService} from '../../services/invoice.service';

@Component({
  selector: 'app-invoice-config',
  templateUrl: './invoice-config.component.html',
  styleUrls: ['./invoice-config.component.scss']
})
export class InvoiceConfigComponent implements OnInit, OnDestroy {
  invoiceForm: FormGroup;
  updateSubscription: Subscription;
  @Input() language: 'en' | 'de' = 'en';
  @Output() invoiceResult = new EventEmitter<InvoiceConfig>();

  constructor(
    private fb: FormBuilder,
    private service: LayoutFormUpdateService,
    private invoiceService: InvoiceService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.updateSubscription = this.service.invoice$.subscribe(invoice => {
      if (invoice.id === -1) {
        invoice.id = null;
        this.invoiceDefaults();
      }

      this.updateForm(invoice);
    });

    this.invoiceForm = this.fb.group({
      senderInformation: ['', [Validators.required, Validators.maxLength(255)]],
      introText: ['', [Validators.required]],
      footerRow1: ['', [Validators.required]],
      footerRow2: ['', [Validators.required]],
      footerRow3: ['', [Validators.required]],
      footerRow4: ['', [Validators.required]]
    });


    this.invoiceForm.valueChanges.subscribe(data => {
      if (this.invoiceForm.valid) {
        this.invoiceResult.emit({...this.invoiceForm.value});
      } else {
        this.invoiceResult.emit(null);
      }
    });
  }

  ngOnDestroy() {
    this.updateSubscription.unsubscribe();
  }

  invoicePreview() {
    const dialogRef = this.dialog.open(InvoiceDialogComponent, {
      width: '50vw',
      height: '80vh',
      data: {
        language: this.language,
        invoice: {
          ...this.invoiceForm.value
        }
      }
    });
  }

  invoiceDefaults() {
    this.invoiceService.defaults(this.language).subscribe(defaultConfig => {
      this.updateForm(defaultConfig);
    });
  }

  private updateForm(invoice) {
    const newline = String.fromCharCode(13, 10);
    this.invoiceForm.patchValue({
      senderInformation: invoice.senderInformation,
      introText: invoice.introText.replace(/\\n/g, newline),
      footerRow1: invoice.footerRow1.replace(/\\n/g, newline),
      footerRow2: invoice.footerRow2.replace(/\\n/g, newline),
      footerRow3: invoice.footerRow3.replace(/\\n/g, newline),
      footerRow4: invoice.footerRow4.replace(/\\n/g, newline),
    });
  }
}
