<form [formGroup]="personForm">
  <p-fieldset legend="Person" i18n-legend="@@PersonFieldsetLabel" class="form-fieldset" [class.valid]="personForm.valid" [class.invalid]="personForm.invalid">

    <p-dropdown formControlName="salutation" [options]="salutations" optionLabel="labelEn" i18n-optionLabel="use labelEn for eng, labelDe for german@@PersonSalutationLabel"></p-dropdown>

    <mat-form-field>
      <input matInput type="text" placeholder="Firstname" i18n-placeholder="@@PersonFirstnamePlaceholder" formControlName="firstname" maxlength="50">
    </mat-form-field>
    <mat-form-field>
      <input matInput type="text" placeholder="Lastname" i18n-placeholder="@@PersonLastnamePlaceholder" formControlName="lastname" maxlength="50">
    </mat-form-field>
    <mat-form-field>
      <input matInput type="email" placeholder="E-Mail" i18n-placeholder="@@PersonEmailPlaceholder" formControlName="mail" maxlength="255">
    </mat-form-field>
  </p-fieldset>
</form>
