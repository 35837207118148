<form [formGroup]="invoiceForm">
  <p-fieldset class="form-fieldset" [class.valid]="invoiceForm.valid" [class.invalid]="invoiceForm.invalid">
    <p-header>
      <span i18n="@@InvoiceFieldsetLabel">Invoice Configuration</span>
      <button mat-raised-button class="btn-header" (click)="invoicePreview()" i18n="@@InvoicePreviewButton">Preview</button>
      <button mat-raised-button class="btn-header" (click)="invoiceDefaults()" i18n="@@InvoiceDefaultsButton">Reset</button>
    </p-header>

    <mat-form-field>
      <input matInput type="text" placeholder="sender information" i18n-placeholder="@@InvoiceSenderInformationPlaceholder" formControlName="senderInformation" maxlength="255">
    </mat-form-field>

    <mat-form-field>
      <textarea matInput placeholder="intro text" i18n-placeholder="@@InvoiceIntroTextPlaceholder" rows="3" formControlName="introText"></textarea>
    </mat-form-field>

    <mat-form-field>
      <textarea matInput placeholder="footer column 1" i18n-placeholder="@@InvoiceFooter1Placeholder" rows="4" formControlName="footerRow1"></textarea>
    </mat-form-field>

    <mat-form-field>
      <textarea matInput placeholder="footer column 2" i18n-placeholder="@@InvoiceFooter2Placeholder" rows="4" formControlName="footerRow2"></textarea>
    </mat-form-field>

    <mat-form-field>
      <textarea matInput placeholder="footer column 3" i18n-placeholder="@@InvoiceFooter3Placeholder" rows="4" formControlName="footerRow3"></textarea>
    </mat-form-field>

    <mat-form-field>
      <textarea matInput placeholder="footer column 4" i18n-placeholder="@@InvoiceFooter4Placeholder" rows="5" formControlName="footerRow4"></textarea>
    </mat-form-field>

  </p-fieldset>
</form>
