import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {TableElement} from '../model/table-element';
import {Router} from '@angular/router';
import {TableObject} from '../model/table-object';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, OnChanges {

  // @Input() displayedColumns: string[];
  @Input() dataSource;
  @Input() extraClass: string;
  @Input() language: 'de' | 'en' = 'en';
  @Input() config: TableObject;
  @Input() editable: boolean;
  @Input() rowClassProperty: string[];
  @Input() rows = '10';
  @Input() listActions?: {link: string, labelEn: string, labelDe: string, icon?: string, class?: string }[];

  @Output() listActionEvent = new EventEmitter<any>();

  columns: string[];
  path: string;

  constructor(private router: Router) {
    this.path = this.router.url;
  }

  ngOnInit() {
    this.handleColumns();
  }

  ngOnChanges(): void {
    this.handleColumns();
  }

  doOutputListaction(listaction, id) {
    this.listActionEvent.emit({action: listaction, id});
  }

  getRowClassValue(rowData) {
    let result = '';
    for (const prop of this.rowClassProperty) {
      result += rowData[prop].toLowerCase() + ' ';
    }
    return result;
  }

  private handleColumns() {
    this.columns = [...this.config.getDisplayedListColumns()];
    if (this.editable) {
      if (!this.columns.includes('edit')) {
        this.columns.push('edit');
      }

    }

    if (this.listActions && this.listActions.length > 0) {
      this.listActions.forEach(action => {
        this.columns.push(`list${action}`);
      });
    }
  }


}
