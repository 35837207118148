import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CertificateConfig} from '../model/certificateConfig';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {InvoiceConfig} from '../model/invoiceConfig';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  constructor(private http: HttpClient) {
  }

  invoicePreview(language: string, invoice: InvoiceConfig): Observable<any> {
    return this.http
      .post(
        `${environment.basicUrl}${environment.apiPath}invoice/preview`,
        {language, invoice}
      );
  }

  get(filepath: string): Observable<any> {
    return this.http.post(`${environment.basicUrl}pdf`, {filepath});
  }

  defaults(language: 'en' | 'de'): Observable<InvoiceConfig> {
    return this.http
      .get<InvoiceConfig>(
        `${environment.basicUrl}${environment.apiPath}invoice/defaults/${language}`
      );
  }
}
