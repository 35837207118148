<p-dialog [(visible)]="displayNewMandatorModal" [modal]="true" [baseZIndex]="10000" [style]="{width: '50vw'}"
          [draggable]="false" [resizable]="false" [closeOnEscape]="false" [closable]="false" [showHeader]="false">
  <p i18n="@@MandatorDetailNewSaveMessage">The new mandator was created successfully. Please configure access to the front-end applications.</p>
  <p-footer>
    <button type="button" pButton icon="pi pi-check" (click)="reloadMandator()" label="Ok" i18n-label="@@MandatorDetailNewSaveButton"></button>
  </p-footer>
</p-dialog>

<button mat-raised-button (click)="backToList()" class="btn-fc-primary">
  <span class="fas fa-chevron-left"></span>
  <ng-container i18n="@@DetailViewBackToListText">back to list</ng-container>
</button>

<div class="row">
  <div class="col-12 col-lg-6">
    <app-user [showLanguage]="true" (userResult)="user = $event" (languageChange)="changeChoosenLanguage($event)"></app-user>
    <app-person (personResult)="person = $event"></app-person>
    <app-company [required]="true" (companyResult)="company = $event"></app-company>
  </div>
  <div class="col-12 col-lg-6">
    <app-address (addressResult)="address = $event"></app-address>
    <app-contact (contactResult)="contact = $event"></app-contact>
    <app-setting (settingResult)="setting = $event"></app-setting>
    <app-fe-unit *ngIf="mandator && mandator.id" [id]="mandator.id" type="mandator" [currentFeUnits]="mandator.units"></app-fe-unit>
  </div>
</div>

<div class="row">
  <div class="col-12 col-lg-6">
    <app-certificate-config
      [language]="chosenLanguage"
      (certificateResult)="certificate = $event"
      (logoMainResult)="logoMain = $event"
      (logoHeaderResult)="logoHeader = $event"
      (signeeLogoResult)="signeeLogo = $event"
    ></app-certificate-config>
  </div>
  <div class="col-12 col-lg-6">
    <app-invoice-config [language]="chosenLanguage" (invoiceResult)="invoice = $event"></app-invoice-config>
  </div>
</div>

<button mat-raised-button (click)="saveMandator()" [disabled]="!areasAreValid()" class="btn-fc-primary mt-5 mb-5">Save</button>
