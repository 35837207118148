import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Contact} from '../../model/contact';
import {Subscription} from 'rxjs';
import {LayoutFormUpdateService} from '../../services/layout-form-update.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit, OnDestroy {
  contactForm: FormGroup;
  updateSubscription: Subscription;
  @Output() contactResult = new EventEmitter<Contact>();

  constructor(
    private fb: FormBuilder,
    private service: LayoutFormUpdateService
  ) { }

  ngOnInit() {
    this.updateSubscription = this.service.contact$.subscribe(contact => {
      this.contactForm.patchValue(contact);
    });

    this.contactForm = this.fb.group({
      phone: ['', [Validators.maxLength(18), Validators.required]],
      fax: ['', [Validators.maxLength(18)]]
    });

    this.contactForm.valueChanges.subscribe(data => {
      if (this.contactForm.valid) {
        this.contactResult.emit({...this.contactForm.value} as Contact);
      } else {
        this.contactResult.emit(null);
      }
    });
  }

  ngOnDestroy() {
    this.updateSubscription.unsubscribe();
  }
}
