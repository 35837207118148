import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Address} from '../../model/address';
import {Subscription} from 'rxjs';
import {LayoutFormUpdateService} from '../../services/layout-form-update.service';
import {CountryService} from '../../services/country.service';
import {Country} from '../../model/country';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit, OnDestroy {
  addressForm: FormGroup;
  updateSubscription: Subscription;
  countries: Country[];
  @Output() addressResult = new EventEmitter<Address>();

  constructor(
    private countryService: CountryService,
    private fb: FormBuilder,
    private service: LayoutFormUpdateService
  ) {
  }

  ngOnInit() {
    this.countries = this.countryService.countries;
    this.updateSubscription = this.service.address$.subscribe((address: Address) => {
      this.addressForm.patchValue({
        ...address,
        country: address.countryCode ? this.countryService.getByCode(address.countryCode) : ''
      });
    });

    this.addressForm = this.fb.group({
      street1: ['', [Validators.maxLength(100), Validators.required]],
      street2: ['', [Validators.maxLength(100)]],
      zip: ['', [Validators.maxLength(8), Validators.required]],
      city: ['', [Validators.maxLength(100), Validators.required]],
      country: ['', [Validators.maxLength(2), Validators.required]],
    });

    this.addressForm.valueChanges.subscribe(data => {
      if (this.addressForm.valid) {
        this.addressResult.emit({
          ...this.addressForm.value,
          countryCode: this.addressForm.get('country').value?.code
        } as Address);
      } else {
        this.addressResult.emit(null);
      }
    });
  }

  ngOnDestroy() {
    this.updateSubscription.unsubscribe();
  }
}
