import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AuthenticationService} from '../../services/authentication.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  private showSidebar = false;
  @Output() showSidebarChanged = new EventEmitter<boolean>();

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  doLogout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  changeSidebar() {
    this.showSidebar = !this.showSidebar;
    this.showSidebarChanged.emit(this.showSidebar);
  }
}
