<form [formGroup]="userForm">
  <p-fieldset legend="User" i18n-legend="@@UserFieldsetLabel" class="form-fieldset" [class.valid]="valid" [class.invalid]="!valid">

    <mat-form-field>
      <input matInput type="text" placeholder="Username" i18n-placeholder="@@UserUsernamePlaceholder" formControlName="username">
    </mat-form-field>

    <mat-form-field>
      <input matInput type="password" placeholder="Password" i18n-placeholder="@@UserPasswordPlaceholder" formControlName="password">
    </mat-form-field>
    <app-password-strength [passwordToCheck]="userForm.value.password" (passwordStrength)="passwordValid($event)"></app-password-strength>


    <div *ngIf="showLanguage" class="language-switch">
      <p-dropdown
        [options]="languages"
        formControlName="language">
        <ng-template let-item pTemplate="selectedItem">
          <img
            src="assets/img/flag/{{ item.value }}.svg"
            style="width:16px;vertical-align:middle; margin-bottom: 0"
          />
          <span style="vertical-align:middle; margin-left: .5em">
          {{ item.label }}
        </span>
        </ng-template>
        <ng-template let-language pTemplate="item">
          <div
            class="ui-helper-clearfix"
            style="position: relative;height: 25px;"
          >
            <img
              src="assets/img/flag/{{ language.value }}.svg"
              style="width:24px;position:absolute;top:6px;left:5px"
            />
            <div style="font-size:14px;padding-left: 3rem;margin-top:4px">
              {{ language.label }}
            </div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>

  </p-fieldset>
</form>
